import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosError } from 'axios';

import Logger from 'vatix-ui/lib/utils/logger';

import { reverse } from 'named-urls';

import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import { EntityValueType, EntityDetailsType } from 'utils/api/types';
import { isNotFound } from 'utils/api/errors';
import { postMessage } from 'utils/events/broadcast';

import { EntityModules } from 'core/constants';

import routes from 'core/routes';

import EntitiesActivities from 'stores/EntitiesActivities';

import EntityDetails from 'stores/EntityDetails/EntityDetails';

import RootStore from '../Root';

export default class SiteDetailsStore extends EntityDetails {
  @observable error?: AxiosError;

  @observable details?: EntityDetailsType;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api, EntityModules.Sites, routes.dashboard.sites.toString(), (uuid: string): string =>
      reverse(routes.dashboard.sites.details, { entityId: uuid })
    );

    makeObservable(this);

    eventBus.register(this);
  }

  @action.bound
  async loadDetails(siteId: string): Promise<void> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadEntitiesDetails(this.entityType, siteId)();
      runInAction(() => {
        this.activities = new EntitiesActivities(this.store, this.api, siteId, this.entityType);
        this.error = undefined;
        this.details = data;
      });
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      // @ts-ignore
      if (!isNotFound(e)) {
        Logger.error(`Invalid load site details API response. Site id: ${siteId}`, e);
      }
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  create = async (siteName: string): Promise<boolean> => {
    try {
      await this.api.createEntity(this.entityType, { siteName })();
      return true;
    } catch (e) {
      Logger.error('Failed to create site', e);

      return false;
    }
  };

  delete = async (siteId: string): Promise<boolean> => {
    try {
      await this.api.deleteEntity(this.entityType, siteId)();
    } catch (e) {
      Logger.error('Failed to delete site', e);
      return false;
    }
    return true;
  };

  edit = async (data: Record<string, EntityValueType>): Promise<boolean> => {
    if (this.details?.uuid === undefined) {
      return false;
    }
    try {
      const edited = await this.api.editEntity(this.entityType, this.details?.uuid, data)();
      this.details = edited.data;
      postMessage(EventType.UpdatedSite, { uuid: edited.data.uuid });
      this.store.notification.enqueueSuccessSnackbar('Site edited successfully');
      return true;
    } catch (e) {
      const error = e as AxiosError;
      this.store.notification.enqueueErrorSnackbar(
        error.response?.data[0] || `The site could not be updated. Something went wrong.`
      );
      Logger.error('Failed to edit site', e);
      return false;
    }
  };

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.details = undefined;
  }

  @action.bound
  async loadCount(): Promise<number> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadEntitiesCount(EntityModules.Sites)();
      runInAction(() => {
        this.error = undefined;
      });
      return data.count;
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      return 0;
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }
}
