import React from 'react';

import { GridValueGetterParams, GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { reverse } from 'named-urls';

import Grid, { defaultRenderers, defaultOperators, defaultFormatters } from 'components/Grid';

import { ExtendedUserOperators } from 'components/Grid/operators/User/User';

import { ColumnDefinition } from 'components/Grid/types';

import { Site } from 'containers/Sites/types';

import { useStore } from 'utils/hooks/store';

import routes from 'core/routes';

import { formatDateForDisplay } from 'utils/formatters/time';

import EntityActionCell from 'components/Entities/EntityActionCell';

import { SitesTableProps } from './types';
import { StyledCreated } from './styles';

export const basicColumns = ([
  {
    field: 'siteName',
    headerName: 'Name',
  },
  {
    field: 'siteManager',
    headerName: 'Manager',
    renderCell: defaultRenderers.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    filterOperators: ExtendedUserOperators(),
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    renderCell: ({ value }: GridValueGetterParams) => (
      <StyledCreated>{formatDateForDisplay(value.value)}</StyledCreated>
    ),
    filterOperators: defaultOperators.date_time(),
    valueFormatter: defaultFormatters.date,
    nativeFilter: 'createdAt',
  },
] as unknown) as ColumnDefinition[];

const SitesTable: React.FunctionComponent<SitesTableProps> = ({ onError, onRefreshCallback }): React.ReactElement => {
  const { routing, siteDetails } = useStore();

  const onRowClick: GridEventListener<'rowClick'> = (item: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.sites.details, { entityId: item.id }));
  };

  return (
    <Grid
      basicColumns={
        ([
          ...basicColumns,
          {
            field: 'actions',
            type: 'actions',
            headerName: '',
            maxWidth: 50,
            ariaLabel: 'actions_cell',
            // eslint-disable-next-line react/no-unused-prop-types
            renderCell: ({ row, colDef }: { row: Site; colDef: ColumnDefinition }) => (
              <EntityActionCell rowData={{ uuid: row.uuid }} actions={colDef.actions} store={siteDetails} />
            ),
            filterOperators: null,
            sortable: false,
          },
        ] as unknown) as ColumnDefinition[]
      }
      entity="sites"
      onError={onError}
      dataURL="/api/entities/sites/"
      fieldsURL="/api/entities/sites/fields/"
      disableSelectAllColumns
      customNoRowsText="No sites have been created yet"
      onRowClick={onRowClick}
      onRefreshCallback={onRefreshCallback}
    />
  );
};

export default SitesTable;
